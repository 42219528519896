import "../CSS/Home.css";
import "../CSS/Morph.io.css";

function Contact() {
    return (
        <>
      <div className="container text-banner">
        <h3 className="font-play-bold text-center Morph_Fc_White">
          Comment me contacter ?
        </h3>
        <center>
          <img
            className="img-fluid myFace"
            src="Assets/photo-julien.jpg"
            alt="Julien Roy - Profil"
          ></img>

          <h5 className="font-play-bold Morph_Fc_White presentation-txt text-center">
            Je suis actuellement à la recherche d'un stage à temps partiel du 16 Septembre 2024 au 2 Mars 2025
          </h5>
          <br/>
            <a
              className="font-play-bold nav-link Morph_Nav_Btn Morph_Fc_White"
              aria-current="page"
              href="/Download/JULIEN_ROY_CV.pdf"
            >
              Cliquez ici pour voir mon CV
            </a>
            <br/>
            <br/>
          <h5 className="font-play-bold Morph_Fc_White presentation-txt text-center">
            Si vous êtes intéréssé par mon profil, n'hésitez pas à me contacter par mail à l'adresse suivante : <a className="nav-link underline" href="mailto:julien.roy@epitech.eu"> julien.roy@epitech.eu</a>
          </h5>
          <br/>
          <h5 className="font-play-bold Morph_Fc_White presentation-txt text-center">
            Vous pouvez également me contacter par téléphone au <a className="nav-link underline" href="tel:0662690939"> 06 62 69 09 39</a> </h5>
        </center>
      </div>
      <br/><br/><br/>
      </>
    );
}

export default Contact;