import { Link } from "react-router-dom";
import "../CSS/Home.css";
import "../CSS/Morph.io.css";

function Home() {
  return (
    <>
      <div className="container text-banner">
        <h3 className="font-play-bold text-center Morph_Fc_White">
          Qui suis-je ?
        </h3>
        <center>
          <img
            className="img-fluid myFace"
            src="Assets/photo-julien.jpg"
            alt="Julien Roy - Profil"
          ></img>

          <h5 className="font-play-bold Morph_Fc_White presentation-txt">
            Je m'appelle Julien, j'ai 20 ans et je suis étudiant en troisième
            année à Epitech Moulins (03000).
          </h5>

          <h5 className="font-play-bold Morph_Fc_White presentation-txt">
            Actuellement inscrit dans le Programme Grande Ecole d'Epitech, je
            souhaite me spécialiser dans le développement web. Depuis mon
            enfance, je suis passionné par l'apprentissage du développement web.
          </h5>
        </center>
      </div>

      <div className="container text-banner">
        <h5 className="font-play-bold Morph_Fc_White text-center">
          Quelques projets réalisés
        </h5>
        <div className="row">
          <div className="col-md-7">
            <center>
              <img
                src="Assets/Intercampus.png"
                className="img-fluid"
                alt="Html Programming Langage Logo"
              />
            </center>
          </div>
          <div className="col-md-5">
            <h6 className="font-play-bold Morph_Fc_White text-center">
              Intercampus
            </h6>

            <h6 className="font-play-bold Morph_Fc_White">
              En 2023, pour l'intercampus de Moulins, nous avons été missionné
              par le PIJ afin de réaliser un site internet
            </h6>
            <h6 className="font-play-bold Morph_Fc_White">
              Ce site avait pour but de permettre aux participants de suivre en
              temps réel le score des épreuves Nous devions permettre au PIJ de
              réaliser des ajouts de point facilement.
            </h6>
            <h6 className="font-play-bold Morph_Fc_White">
              Ce site web à été un challenge pour moi et André Stana, car nous
              devions le réaliser dans un temps limité.
            </h6>
          </div>
        </div>

        <br />

        <div className="row">
          <div className="col-md-5">
            <h6 className="font-play-bold Morph_Fc_White text-center">
              Scoreboard de la Piscine Tek 1
            </h6>

            <h6 className="font-play-bold Morph_Fc_White">
              En 2023, a l'occasion de la Piscine de la deuxième promotion
              d'Epitech Moulins, nous avons été challengé par notre directeur
              avec André Stana afin de réaliser un oard pour leurs Piscine
            </h6>
            <h6 className="font-play-bold Morph_Fc_White">
              Le plus compliqué ici était de trouver un design que nous aimions.
            </h6>
            <h6 className="font-play-bold Morph_Fc_White">
              Ce Scoreboard a été réalisé en quelques jours, en PHP.
            </h6>
          </div>

          <div className="col-md-7">
            <center>
              <img
                src="Assets/Piscine_TEK_1.png"
                className="img-fluid"
                alt="Html Programming Langage Logo"
              />
            </center>
          </div>
        </div>
      </div>

      <div className="container text-banner">
        <h5 className="font-play-bold Morph_Fc_White text-center">
          Les technologies que j'utilise
        </h5>
        <div className="row">
          <div className="col-md-3">
            <center>
              <img
                src="Assets/html-512.png"
                className="img-fluid skills-img"
                alt="Html Programming Langage Logo"
              />
            </center>
          </div>
          <div className="col-md-3">
            <center>
              <img
                src="Assets/css-512.png"
                className="img-fluid skills-img"
                alt="Css Programming Langage Logo"
              />
            </center>
          </div>
          <div className="col-md-3">
            <center>
              <img
                src="Assets/react_logo-512.png"
                className="img-fluid skills-img"
                alt="React Programming Langage Logo"
              />
            </center>
          </div>

          <div className="col-md-3">
            <center>
              <img
                src="Assets/nodejs_original_logo_icon_146411.png"
                className="img-fluid skills-img"
                alt="NodeJS Programming Langage Logo"
              />
            </center>
          </div>
        </div>
      </div>

      <div className="container text-banner">
        <h5 className="font-play-bold Morph_Fc_White text-center">
          Je suis également vidéaste
        </h5>

        <div className="row">
          <div className="col-md-6">
            <a href="https://youtu.be/thdrV5rd_KE" target="_blank">
              <img
                className="img-fluid youtube_video"
                src="Assets/Video_1.jpg"
                alt="Vidéo Youtube"
              />
            </a>
            <h6 className="font-play-bold Morph_Fc_White text-center">
              Présentation d'un projet web
            </h6>
          </div>

          <div className="col-md-6">
            <a href="https://youtube.com/watch?v=exxH9PYJV5k" target="_blank">
              <img
                className="img-fluid youtube_video"
                src="Assets/Video_2.jpg"
                alt="Vidéo Youtube"
              />
            </a>
            <h6 className="font-play-bold Morph_Fc_White text-center">
              Présentation d'une API Rappelz
            </h6>
          </div>
        </div>
        <Link
          className="font-play-bold nav-link Morph_Nav_Btn Morph_Fc_White text-center"
          to="/projets"
        >
          {" "}
          Voir d'autres projets{" "}
        </Link>
      </div>

      <div className="container text-banner">
        <h5 className="font-play-bold Morph_Fc_White text-center">
          Liens utiles
        </h5>
        <center>
          <div className="usefull-box">
            <a
              className="font-play-bold nav-link Morph_Nav_Btn Morph_Fc_White"
              aria-current="page"
              href="/Download/JULIEN_ROY_CV.pdf"
            >
              Mon CV
            </a>
            <Link
              className="font-play-bold nav-link Morph_Nav_Btn Morph_Fc_White"
              aria-current="page"
              to="/projets"
            >
              Mes Projets
            </Link>
          </div>
        </center>

        <center>
          <div className="usefull-box">
            <Link
              className="font-play-bold nav-link Morph_Nav_Btn Morph_Fc_White"
              aria-current="page"
              to="/contact"
            >
              Contact
            </Link>
          </div>
        </center>
      </div>
      <br/><br/><br/>
    </>
  );
}

export default Home;
