import './App.css';
import Navbar from './Components/Utils/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Components/Pages/Home';
import Footer from './Components/Utils/Footer';
import Contact from './Components/Pages/Contact';
import Projets from './Components/Pages/Projets';
import NotFound from './Components/Pages/NotFound';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/projets" element={<Projets />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    <Footer/>
    </Router>
    
  );
}

export default App;
