import React from 'react';
import '../CSS/Footer.css';
import '../CSS/Home.css';

const Footer = () => {
    return (
        <footer className="footer">
            {/* Your footer content here */}
            <h6 className="font-play-bold text-center Morph_Fc_White">
            Julien ROY - 2024
            </h6>
        </footer>
    );
};

export default Footer;
