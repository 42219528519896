import '../CSS/navbar.css';
import '../CSS/Morph.io.css';
import '../CSS/Home.css';
import { Link } from 'react-router-dom';

function Navbar() {
    return (
      <nav className="navbar navbar-expand-md navbar-dark navbar-background">
        <div className="container-fluid">
          <a className="order-1 navbar-brand d-md-none" href="#">
            <img src="./Assets/main-logo.png" alt=""/>
          </a>
          <button className="navbar-toggler custom-toggler order-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <svg className="navbar-toggler-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="30" height="30">
              <rect x="10" y="25" width="80" height="10" fill="#ffffff" />
              <rect x="10" y="45" width="80" height="10" fill="#ffffff" />
              <rect x="10" y="65" width="80" height="10" fill="#ffffff" />
            </svg>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav mx-auto">
              <li className="nav-item">
                <Link className="font-play-bold nav-link Morph_Nav_Btn active" aria-current="page" to="/">Accueil</Link>
              </li>
              <li className="nav-item">
                <Link className="font-play-bold nav-link Morph_Nav_Btn" to="/projets">Projets</Link>
              </li>
              <a className="navbar-brand d-none d-md-block" href="#">
                <img src="./Assets/main-logo.png" alt=""/>
              </a>
              <li className="nav-item">
                <a className="font-play-bold nav-link Morph_Nav_Btn" href="https://github.com/Derkino" target='_blank'>Github</a>
              </li>
              <li className="nav-item">
                <Link className="font-play-bold nav-link Morph_Nav_Btn" to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }

export default Navbar;
