import React from 'react';
import { Link } from 'react-router-dom';
import '../CSS/notfound.css';

function NotFound() {
    return (
      <div className="not-found-container">
        <div className="not-found-content">
          <div className="not-found-illustration">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" className="not-found-svg">
              <circle cx="27" cy="27" r="18" stroke="#e74c3c" strokeWidth="4" fill="none"/>
              <line x1="40" y1="40" x2="52" y2="52" stroke="#e74c3c" strokeWidth="4"/>
            </svg>
          </div>
          <h1 className="not-found-title">404</h1>
          <p className="not-found-subtitle">Page non trouvée</p>
          <p className="not-found-text">Oups, la page recherchée n'a pas été trouvée<br/><br/>Cliquez sur le bouton pour retourner à l'accueil</p>
          <Link to="/" className="not-found-link">Retour</Link>
        </div>
      </div>
    );
  }

export default NotFound;
