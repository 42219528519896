import "../CSS/Home.css";
import "../CSS/Morph.io.css";

function Projets() {
    return (
        <>
      <div className="container text-banner">
        <h3 className="font-play-bold text-center Morph_Fc_White">
          Voici quelques un de mes projets
        </h3>
      </div>
      <div className="container text-banner">
        <h5 className="font-play-bold Morph_Fc_White text-center">
          Quelques projets réalisés
        </h5>
        <div className="row">
          <div className="col-md-7">
            <center>
              <img
                src="Assets/CDN.png"
                className="img-fluid"
                alt="Html Programming Langage Logo"
              />
            </center>
          </div>
          <div className="col-md-5">
            <h6 className="font-play-bold Morph_Fc_White text-center">
              Mon propre CDN
            </h6>

            <h6 className="font-play-bold Morph_Fc_White">
            Un CDN ( Content Network Delivery ) est une manière de diffuser du contenu ( des fichiers, des images, des videos ) facilement en permettant à un utilisateur de l'uploader
            </h6>
            <h6 className="font-play-bold Morph_Fc_White">
            Voulant centraliser mes images concernant mes sites web, j'ai eu l'idée de réaliser mon propre CDN
            </h6>
            <h6 className="font-play-bold Morph_Fc_White">
            Ce dernier est à usage privé, et permet d'uploader facilement des images pour mes projets.
            </h6>
          </div>
        </div>

        <br />

        <div className="row">
          <div className="col-md-5">
            <h6 className="font-play-bold Morph_Fc_White text-center">
              Rappelz Fetcher
            </h6>

            <h6 className="font-play-bold Morph_Fc_White">
                Le projet Rappelz Fetcher à pour but de réaliser une API lié au jeu 'Rappelz'
            </h6>
            <h6 className="font-play-bold Morph_Fc_White">
                Rappelz est un MMORPG dont le code source à fuité il y a quelques années nous offrant des possibilitées innoventes<br/>
                Cette API me permet de récuperer des statistiques sur le jeu, permettant à tout moment de les intégrer dans un bot discord, un site web, ou d'autre type d'application facilement
            </h6>
            <h6 className="font-play-bold Morph_Fc_White">
            Cette API est utilisé par des personnes souhaitant réaliser des 'Call-API' facilement
            </h6>
          </div>

          <div className="col-md-7">
            <center>
              <img
                src="Assets/RappelzFetcher.png"
                className="img-fluid"
                alt="Html Programming Langage Logo"
              />
            </center>
          </div>
        </div>
        <br/>
        <div className="row">
          <div className="col-md-7">
            <center>
              <img
                src="Assets/oldPort.png"
                className="img-fluid"
                alt="Html Programming Langage Logo"
              />
            </center>
          </div>
          <div className="col-md-5">
            <h6 className="font-play-bold Morph_Fc_White text-center">
              Mon ancien portfolio
            </h6>

            <h6 className="font-play-bold Morph_Fc_White">
            Mon ancien portfolio, était pour moi, un challenge, une manière de découvrir de nouvelles technologies, et de réaliser des ajouts facilement.
            </h6>
            <h6 className="font-play-bold Morph_Fc_White">
            Ce dernier m'a appris à :
            </h6>
            <h6 className="font-play-bold Morph_Fc_White">
            - Utiliser un Proxy afin de rendre accessible mon API<br/>
            - Repenser le design de mon portfolio<br/>
            - Comprendre CORS<br/>
            - Utiliser CertBot<br/>
            </h6>
            <h6 className="font-play-bold Morph_Fc_White">
            Même si il n'était pas 100% fonctionnel, je me suis beaucoup amusé à essayer d'obtenir un joli design
            </h6>
          </div>
        </div>

        </div>
        <br/><br/><br/>
      </>
    );
}

export default Projets;